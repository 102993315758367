.App {
  overflow: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo&display=swap');
.sus{
  display: flex;
  width: 100%;
}
.right{
  background-color: rgb(4, 34, 65);
  height: 280px;
  
}
.right p {
  color: white;
  margin: 15px;
  font-size:medium;
  font-family: 'Arimo', sans-serif;
}
.right h2{
  margin-top: 15px;
  margin-bottom: 10px;
  color: #7ed957;
  text-align: center;
  font-weight: bold;
  font-family: 'Anton', sans-serif;
}








.imggal{
  display: flex;
  flex-direction: row;
  max-width: 100%;
  
}
.gallery {
 display: flex;
 

  /* margin: 5px; */
 margin-top: 10px;
 padding-bottom: 0px;
 margin-right: 20px;
 margin-left: 90px;
  /* float: left; */

  
}
.right_div{
  display: flex;
  flex-direction: column;
}
.left_div{
  display: flex;
  flex-direction: column;
}

.desc{
  font-size: 20px;
  /* padding-left: 40px; */
}

.gallery img {
  width: 15vw;
  height: auto;
}
.dot_bo{
  border-top: 1px dashed grey;
}


/* themes */
.themes{
  color: #7ed957;
  text-align: center;
  font-weight: bold;
  font-size: 70px;
  font-family: 'Anton', sans-serif;
}
.fade_themes{
  text-align: center;
  font-weight:bolder;
  font-size: 180px;
  opacity: calc(0.1);
  margin-top: -100px;
  margin-bottom: -80px;
  font-family: 'Anton', sans-serif;
}


/* register  */
.reg{
  background-color:rgb(4, 34, 65);
  padding-top: 25px;
  padding-left: 60px;
  margin-top: 20px;
}
.reg h1{
  color: #7ed957;
  font-weight: 400;
  font-family: 'Anton', sans-serif;
}
.how{
  color: rgb(82, 203, 211);
  font-weight: 500;
  margin-bottom: 0;
  padding-bottom: 0;
  font-family: 'Arimo', sans-serif;
}
.info{
  color: white;
  font-family: 'Arimo', sans-serif;
  
}
.info1{
  color: white;
  margin-bottom: -20px;
  font-family: 'Arimo', sans-serif;
}
.team{
  background-color:rgb(4, 34, 65);
  margin-top: 0;
  padding-top: 25px;
  padding-left: 60px;
}
.team h1{
  color: #7ed957;
  
}
.mode_of{
  display: flex;
  flex-direction: row;
}
.mode_of5{
  width: 50%;
  height: auto;
}
.mode_of6{
  width: 50%;
  height: auto;
}


.prob{
  background-color:rgb(4, 34, 65);
  margin-top: 0;
  padding-top: 25px;
  padding-left: 60px;
}
.prob h1{
  color: #7ed957;
  font-size: 50px;
  margin-bottom: 30px;
  font-weight: 700;
  font-family: 'Anton', sans-serif;
}
.prize{
  color: rgb(82, 92, 234);
  font-size: 80px;
  font-weight: 500;
  text-align: right;
  font-family: 'Anton', sans-serif;
}

.hack{
  display: flex;
  flex-direction: row;
}
.hack1{
  width: 60%;
  height: auto;
}
.hack2{
  width: 40%;
  height: auto;
}

.rules{
  background-color: rgb(4, 34, 65);
  margin-bottom: -103px;
}
.rules li{
  color:rgb(124, 90, 194);
}
.pad{
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 80px;
  font-family: 'Arimo', sans-serif;
}

.footer_top{
  display: flex;
  flex-direction:row;
  justify-content:space-around;
  background-color: rgb(4, 34, 65);
}
.footer_top button{
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.but1{
  margin-left: 20vw;
  background-color: rgb(82, 92, 234);
  color: white;
  border-radius: 15px;
  font-weight: 700;
}
.but3{
  margin-right: 20vw;
  background-color: rgb(82, 92, 234);
  color: white;
  font-weight: 700;
  border-radius: 15px;
}
.but2{
  background-color: rgb(82, 92, 234);
  color: white;
  font-weight: 700;
  border-radius: 15px;
}
.footer_top button i{
 color: #7ed957

}


.footer{
  height: 25vh;
}
.f1{
  margin-top: 6vh;
  margin-left: 5vw;
  letter-spacing: 1px;
  text-decoration: none;
}
.f1 a{
  text-decoration: none;
  color: black;
}
.f2{
  margin-top: 4vh;
  margin-left: 5vw;
  margin-bottom: 5vh;
 
}
.icons{
  margin-top: 4vh;
  margin-left: 5vw;
  size: 10px;
  
}
.icons span a{
  color: black;
}
.copy{
  margin-left: 6vw;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
}
.copy img{
  height:150px;
  width: auto;
  align-items:flex-end;
  margin-top: -100px;
 
}
.f1 span{
  padding: 12px;
  letter-spacing: 1px;
}
.f2 span{
  padding: 12px;
  
}
.con{
  margin-left: 8vw;
 
  
}
.party{
  letter-spacing: 1px;
}
.party a{
  text-decoration: none;
  color: black;
}






@media (max-width: 768px) {
  .sus{
    flex-direction: column;
  }
 .left img{
   width: 100%;
   height: auto;
 }
 .imggal{
   display: flex;
   flex-direction: column;
 }
 .right_div{
   display: flex;
   flex-direction: column;
 }
 .left_div{
   display: flex;
   flex-direction: column;
 }
 .gallery img{
  width: 30vw;
  height: auto;
 }
 .gallery{
   margin-left: 0;
 }
 .mode_of5{
   display: none;
 }
 .mode_of6{
   width: 100%;
   
 }
 .prize{
   font-size: 30px;
   text-align: left;
 }
 .hack2{
   display: none;
 }
 .hack1{
   width: 100%;
 }
 .copy{
   flex-direction: column;
   height: 40vh;
 }

.icons span{
  padding: 6px;
}
.but1,.but2,.but3{
  font-size: xx-small;
  padding: 0;
  margin: 0;
}

}
