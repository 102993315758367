.apply{
    border-radius: 20px;
    font-weight:700;
    color: rgb(4, 34, 65);
    padding-right: 25px;
    padding-left: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.about a{
    text-decoration: none;
    color: white;
    font-weight: 400;
    letter-spacing: 1.5px;
}
.part a{
    text-decoration: none;
    color: white;
    letter-spacing: 1.5px;
}

.underline-small{
    display:inline-block;
    position:relative;
    
  }

  .underline-small::after{
    content:'';
    height:0.1px;
    width:300%;
    background:white;
    position:absolute;
    left:calc(32% - 10%);
    bottom:20px;
  }

.container{
    background-image: url('./images/nav\ bar\ text.png');
    background-size:contain;
    background-repeat: no-repeat;
    background-position: right;
}

@media (max-width: 768px) {

.underline-small::after{
    height: 0;
}


}